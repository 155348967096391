<template>
  <div class="container">
    <div class="section">
      <HeaderCom class=""></HeaderCom>
    </div>
    <div class="container1">
      <div class="section section1">
         <div>AiChina超级CDN</div>
         <div>满足互联网内容分发需求</div>
         <div>( AiChina Cloud Content Delivery Network )服务，即内容分发网络, UCloud CDN将用户的
加速内容分发至部署在全球的近500个服务节点，并进行智能调控和缓存,为用户计算最近的
访问节点,提供给客户更好更快的网络体验。</div>
      </div>
    </div>
    <div class="section section2">
        <div class="active"><a href="#node">节点覆盖</a></div>
        <div><a href="#core">核心功能</a></div>
        <div><a href="#secene">应用场景</a></div>
    </div>
    
    <div class="clearfix"></div>
    <div class="section section3" id="node">
      <div class="sec-title">国内外优质节点覆盖，用户随处可用</div>
      <div class="sec-item">
        <div class="sec-node">
          <div>
            <i></i>
          </div>
          <div>
            全球 <b>2000</b> 节点
          </div>
        </div>
        <div class="sec-node">
          <div>
            <i></i>
          </div>
          <div>
            <b>20+</b>  运营商覆盖
          </div>
        </div>
        <div class="sec-node">
          <div>
            <i></i>
          </div>
          <div>
            访问提速 <b>80%</b>    
          </div>
        </div>
        <div class="sec-node">
          <div>
            <i></i>
          </div>
          <div>
            <b>100万</b>   客户选择
          </div>
        </div>
      </div>
      <div class="sec-item">
        <img src="@/assets/images/cdn_cn_map.png" alt="">
      </div>
    </div>
    <div class="container2" id="core">
      <div class="section section4">
        <div class="sec-title">核心功能及服务</div>
        <div class="sec-item">
          <div class="sec-srv">
            <i></i>
            <div class="sec-srv-title">无盲区覆盖</div>
            <div class="sec-srv-desc">精选主流厂商高质量节点，全面覆盖各地区、各运营商的网络，实现全地域无盲区的覆盖</div>
          </div>

          <div class="sec-srv">
            <i></i>
            <div class="sec-srv-title">零故障品控</div>
            <div class="sec-srv-desc">基于第三方 APM 的全网覆盖和七牛自研的监控 数据，打造七牛 CDN 零故障质量保障体系</div>
          </div>

          <div class="sec-srv">
            <i></i>
            <div class="sec-srv-title">精准智能调度</div>
            <div class="sec-srv-desc">支持 DNS、HTTPDNS、HTTP302 调度模 式，有效提高访问响应速度，防止劫持，实现 全网实时精准调度</div>
          </div>

          <div class="sec-srv">
            <i></i>
            <div class="sec-srv-title">降低回源成本</div>
            <div class="sec-srv-desc">自建中间源，消除跨运营商回源慢甚至不可达 等问题，保护源站不受边缘节点请求波动影 响，节省回源成本</div>
          </div>

          <div class="sec-srv">
            <i></i>
            <div class="sec-srv-title">灵活分时计费</div>
            <div class="sec-srv-desc">日间、闲时、忙时可分开计费，根据使用场景 精细化运营，为用户最大化降低成本费用</div>
          </div>

          <div class="sec-srv">
            <i></i>
            <div class="sec-srv-title">私有协议优化</div>
            <div class="sec-srv-desc">使用私有传输协议作为 CDN 内部数据交换的 基础，确保客户资源在 CDN 内部安全、快速 的传输到各个节点，实现客户内容高效</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section5" id="secene">
      <div class="sec-title">
        应用场景
      </div>
      <div class="sec-item sec-item-core">
        <div>网站加速</div>
        <div>超大文件下载</div>
        <div>音视频点播</div>
        <div>动态加速</div>
        <div>全球加速</div>
      </div>
      <div style="width:100;height: 1px;background: #E8EBF7;"></div>
      <div class="sec-item sec-item-secene">
        <div><img src="@/assets/images/cdn_scenes_img.png" alt=""/></div>
        <div>
          <div class="sec-item-secene-title">场景描述</div>
          <div class="sec-item-secene-desc">
            网页静态资源优化加速分发，例如：HTML、CSS、JS、IMG、短视频等。
          </div>
          <div class="sec-item-secene-title">业务价值</div>
          <div class="sec-item-secene-desc sec-item-secene-desc1"><i></i> 全站 HTTPS 保证网站访问安全；TCP 压缩优化使网页大图、样式等完成秒级加载，缩短网页响应时间，提高用户体验。</div>
          <div class="sec-item-secene-desc sec-item-secene-desc1"><i></i>配合AiChina云-图片处理服务一起使用，还可以针对图片进行缩略、打水印、转格式等数据处理服务。</div>
        </div>
      </div>
    </div>
    <FooterCom></FooterCom>
  </div>
</template>

<script>
import HeaderCom from "@/components/header"
import FooterCom from "@/components/footer"

export default {
  name: 'App',
  components: {
    HeaderCom,
    FooterCom
  },
  props:{}
}
</script>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.container{width:100%;min-width: 1320px;}
.container1{height:458px;background: url("@/assets/images/cdn_banner_bg.png") no-repeat;background-size: 1920px 458px;background-position: center;}
.section{width:1320px;margin:0 auto;}
.section1{height:458px;}
.section1>div:nth-child(1){font-size: 48px;
font-weight: bold;
color: #FFFFFF;
line-height: 80px;padding-top:104px;}
.section1>div:nth-child(2){
  font-size: 28px;
font-weight: 400;
color: #FFFFFF;
line-height: 58px;
}
.section1>div:nth-child(3){
  width:720px;
  font-size: 18px;
font-weight: 400;
color: #FFFFFF;
line-height: 30px;
  justify-content: space-between;
}

.section2{display: flex;flex-direction: row;justify-content: center;height:56px;padding-top:16px;border-bottom: 1px solid #E8EBF7;padding-bottom:5px;}
.section2>div{width:64px;padding:0 50px;font-size: 16px;
font-weight: 400;
color: #41567C;
line-height: 58px;}
.section2>div.active{
  color: #00D177;
  position: relative;
}
.section2>div a{color:#41567C}
.section2>div.active a{color:#00D177}
.section2>div.active::after{
  position:absolute;
  content:"";
  width: 66px;
  height: 4px;
  background: #00D177;
  left:50px;bottom:-4px;

}

.sec-title{font-size: 28px;
font-weight: 400;
color: #41567C;
line-height: 58px;text-align: center;padding-top:93px;}

.sec-item{display: flex;flex-direction: row;justify-content: center;flex-wrap: wrap;}
.sec-node{width: 270px;
height: 135px;
background: #FFFFFF;
border: 1px solid #E8EBF7;
box-shadow: 0px 6px 8px 0px #F5F5F5;margin-right:30px;margin-top:50px;margin-bottom: 50px;
padding-top:21px;text-align: center;}
.sec-node:last-child{margin-right:0;}
.sec-node i{display: block;width:58px;height:58px;margin:0 auto;background:url('@/assets/images/cdn_node_img.png') no-repeat;margin-bottom:22px;}
.sec-node:nth-child(2) i{background-position: -58px 0;}
.sec-node:nth-child(3) i{background-position: -116px 0;}
.sec-node:last-child i{background-position:-174px 0;}

b{font-style: normal;font-weight: 500;color:#00D177}

.container2{margin-top:96px;height: 808px;background: #F8FCFF;}


.sec-srv{width:280px;height:226px;margin-top:90px;}
.sec-srv:not(:nth-child(3n)){margin-right:125px}
.sec-srv i{width:66px;height:66px;display: block;margin:0 auto;background: url('@/assets/images/cdn_core_img.png') no-repeat;}
.sec-srv:nth-child(2) i{background-position:-66px;}
.sec-srv:nth-child(3) i{background-position:-132px;}
.sec-srv:nth-child(4) i{background-position:-198px;}
.sec-srv:nth-child(5) i{background-position:-264px;}
.sec-srv:nth-child(6) i{background-position:-330px;}

.sec-srv-title{text-align: center;font-size: 18px;
font-weight: 400;
color: #41567C;
line-height: 58px;}
.sec-srv-desc{font-size: 14px;
font-weight: 400;
color: #949694;
line-height: 22px;}

.sec-item-core div{margin-right:47px;font-size: 16px;
font-weight: 400;
color: #41567C;
line-height: 58px;}
.sec-item-core div:last-child{margin-right:0}
.sec-item-secene{margin-top:35px;}
.sec-item-secene>div:first-child{margin-top:34px;}
.sec-item-secene>div:last-child{width: 338px;
height: 372px;
padding:24px 21px;
background: #FFFFFF;
border: 1px solid #E8EBF7;
box-shadow: 0px 6px 8px 0px #F5F5F5;margin-left:64px;margin-bottom:150px;}
.sec-item-secene-title{font-size: 16px;
font-weight: 400;
color: #41567C;
line-height: 58px;}

.sec-item-secene-desc{font-size: 14px;
font-weight: 400;
color: #949694;
line-height: 22px;
margin-bottom: 21px;}
.sec-item-secene-desc1{position: relative;padding-left:15px;}
.sec-item-secene-desc1 i{
  position: absolute;
  display:block;width: 6px;
height: 6px;
background: #00D177;
border-radius: 50%;
top:8px;left:0px;}
</style>
